export enum Status {
  ACTIVE = "active",
  INACTIVE = "inactive"
}

export enum ClientStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DRAFT = "draft"
}

export enum ProjectStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DRAFT = "draft"
}
