import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "acronym",
  pure: true
})
export class AcronymPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.match(/\b(\w)/g).join("");
    } else {
      return value;
    }
  }
}
