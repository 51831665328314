export enum PermissionType {
  DASHBOARD_VIEW = "DASHBOARD.VIEW",
  DASHBOARD_INSPECTION_VIEW = "DASHBOARD.INSPECTION.VIEW",
  DASHBOARD_CLIENT_VIEW = "DASHBOARD.CLIENT.VIEW",
  DASHBOARD_PROJECT_VIEW = "DASHBOARD.PROJECT.VIEW",
  DASHBOARD_USER_VIEW = "DASHBOARD.USER.VIEW",
  DASHBOARD_TOOL_VIEW = "DASHBOARD.TOOL.VIEW",
  DASHBOARD_REQUEST_AND_REPORT_VIEW = "DASHBOARD.REQUEST_AND_REPORT.VIEW",
  TOOL_VIEW_ANALYTICS_REPORT = "TOOL.STATUS_REPORT.VIEW",

  ANNOUNCEMENT_CREATE = "ANNOUNCEMENT.CREATE",
  ANNOUNCEMENT_EDIT = "ANNOUNCEMENT.EDIT",
  ANNOUNCEMENT_DELETE = "ANNOUNCEMENT.DELETE",
  ANNOUNCEMENT_VIEW = "ANNOUNCEMENT.VIEW",

  MARKET_CREATE = "MARKET.CREATE",
  MARKET_EDIT = "MARKET.EDIT",
  MARKET_DELETE = "MARKET.DELETE",

  STATE_CREATE = "STATE.CREATE",
  STATE_EDIT = "STATE.EDIT",
  STATE_DELETE = "STATE.DELETE",

  INSPECTION_REPORT_CREATE = "INSPECTION.REPORT.CREATE",
  INSPECTION_REPORT_EDIT = "INSPECTION.REPORT.EDIT",
  INSPECTION_REPORT_VIEW = "INSPECTION.REPORT.VIEW",
  INSPECTION_REPORT_VIEW_ASSIGNED = "INSPECTION.REPORT.VIEW_ASSIGNED",
  INSPECTION_REPORT_DELETE = "INSPECTION.REPORT.DELETE",

  INSPECTION_VIEW_ASSIGNED = "INSPECTION.VIEW_ASSIGNED",
  INSPECTION_EDIT_ASSIGNED = "INSPECTION.EDIT_ASSIGNED",
  INSPECTION_DELETE_ASSIGNED = "INSPECTION.DELETE_ASSIGNED",
  INSPECTION_FINALIZE_ASSIGNED = "INSPECTION.FINALIZE_ASSIGNED",
  INSPECTION_VIEW = "INSPECTION.VIEW",
  INSPECTION_EDIT = "INSPECTION.EDIT",
  INSPECTION_DELETE = "INSPECTION.DELETE",
  INSPECTION_FINALIZE = "INSPECTION.FINALIZE",
  INSPECTION_SSWR_CERTIFY = "INSPECTION.SSWR_CERTIFY",
  INSPECTION_ADD_EDIT_RESPONSIVE_ACTION = "INSPECTION.ADD_EDIT_RESPONSIVE_ACTION",
  INSPECTION_DELETE_RESPONSIVE_ACTION = "INSPECTION.DELETE_RESPONSIVE_ACTION",
  INSPECTION_SIGN_OFF_RESPONSIVE_ACTION = "INSPECTION.SIGN_OFF_RESPONSIVE_ACTION",
  INSPECTION_EXPORT = "INSPECTION.EXPORT",
  INSPECTION_CREATE = "INSPECTION.CREATE",
  INSPECTION_MODIFY_AFTER_FINALIZE = "INSPECTION.MODIFY_AFTER_FINALIZE",
  INSPECTION_PRINT_PDF = "INSPECTION.PRINT_PDF",
  INSPECTION_CAN_REMAIL = "INSPECTION.CAN_REMAIL",
  INSPECTION_CAN_VIEW_RECEPIENTS = "INSPECTION.VIEW_RECEPIENTS",

  CLIENT_EDIT_ASSIGNED = "CLIENT.EDIT_ASSIGNED",
  CLIENT_DELETE_ASSIGNED = "CLIENT.DELETE_ASSIGNED",
  CLIENT_VIEW_ASSIGNED = "CLIENT.VIEW_ASSIGNED",
  CLIENT_EDIT = "CLIENT.EDIT",
  CLIENT_DELETE = "CLIENT.DELETE",
  CLIENT_VIEW = "CLIENT.VIEW",
  CLIENT_EXPORT = "CLIENT.EXPORT",
  CLIENT_CREATE = "CLIENT.CREATE",

  TOOL_SELF_EMAIL_CHANGE_VIEW = "TOOL.SELF_EMAIL_CHANGE_VIEW",
  TOOL_EMAIL_CHANGE_MODULE_VIEW = "TOOL.EMAIL_CHANGE_MODULE.VIEW",
  TOOL_LEADS_MODULE_VIEW = "TOOL.LEADS_MODULE.VIEW",
  EMAIL_CHANGE_CREATE = "EMAIL_CHANGE.CREATE",
  EMAIL_CHANGE_EDIT = "EMAIL_CHANGE.EDIT",
  EMAIL_CHANGE_DELETE = "EMAIL_CHANGE.DELETE",
  CHANGE_EMAIL_FOR_ALL_PROJECTS_OF_SELECTED_MARKET = "EMAIL_CHANGE.CHANGE_EMAIL_FOR_ALL_PROJECTS_OF_SELECTED_MARKET",
  CHANGE_EMAIL_FOR_ALL_PROJECTS_OF_SELECTED_CLIENT = "EMAIL_CHANGE.CHANGE_EMAIL_FOR_ALL_PROJECTS_OF_SELECTED_CLIENT",

  PROJECT_EDIT_ASSIGNED = "PROJECT.EDIT_ASSIGNED",
  PROJECT_DELETE_ASSIGNED = "PROJECT.DELETE_ASSIGNED",
  PROJECT_VIEW_ASSIGNED = "PROJECT.VIEW_ASSIGNED",
  PROJECT_EDIT = "PROJECT.EDIT",
  PROJECT_DELETE = "PROJECT.DELETE",
  PROJECT_VIEW = "PROJECT.VIEW",
  PROJECT_EXPORT = "PROJECT.EXPORT",
  PROJECT_CREATE = "PROJECT.CREATE",

  USER_EDIT_ASSIGNED = "USER.EDIT_ASSIGNED",
  USER_DELETE_ASSIGNED = "USER.DELETE_ASSIGNED",
  USER_VIEW_ASSIGNED = "USER.VIEW_ASSIGNED",
  USER_EDIT = "USER.EDIT",
  USER_EDIT_WITH_SENSITIVE_INFORMATION = "USER.EDIT_WITH_SENSITIVE_INFORMATION",
  USER_DELETE = "USER.DELETE",
  USER_VIEW = "USER.VIEW",
  USER_EXPORT = "USER.EXPORT",
  USER_CREATE = "USER.CREATE",
  USER_VIEW_CLIENT = "USER.VIEW_CLIENT",
  USER_VIEW_WITH_SENSITIVE_INFORMATION = "USER.VIEW_WITH_SENSITIVE_INFORMATION",
  USER_RESET_OTHERS_PASSWORD = "USER.RESET_OTHERS_PASSWORD",

  TOOL_LEAD_VIEW = "TOOL.LEAD.VIEW",
  TOOL_LEAD_CREATE = "TOOL.LEAD.CREATE",
  TOOL_LEAD_EXPORT = "TOOL.LEAD.EXPORT",
  TOOL_LEAD_EDIT = "TOOL.LEAD.EDIT",
  TOOL_LEAD_DELETE = "TOOL.LEAD.DELETE",

  TOOL_TROUBLE_TICKET_VIEW = "TOOL.TROUBLE_TICKET.VIEW",
  TOOL_TROUBLE_TICKET_CREATE = "TOOL.TROUBLE_TICKET.CREATE",
  TOOL_TROUBLE_TICKET_UPDATE = "TOOL.TROUBLE_TICKET.EDIT",
  TOOL_TROUBLE_TICKET_DELETE = "TOOL.TROUBLE_TICKET.DELETE",
  TOOL_TROUBLE_TICKET_EXPORT = "TOOL.TROUBLE_TICKET.EXPORT",
  TOOL_TROUBLE_TICKET_EDIT_CATEGORY_AND_URGENCY_LIST = "TOOL.TROUBLE_TICKET.EDIT_CATEGORY_AND_URGENCY_LIST",

  TOOL_FILE_UPLOAD_VIEW = "TOOL.FILE_UPLOAD.VIEW",
  TOOL_FILE_UPLOAD_UPLOAD = "TOOL.FILE_UPLOAD.UPLOAD",
  TOOL_FILE_UPLOAD_DELETE = "TOOL.FILE_UPLOAD.DELETE",

  TOOL_EMAIL_DISTRIBUTION_CREATE = "TOOL.EMAIL_DISTRIBUTION.CREATE",
  TOOL_EMAIL_DISTRIBUTION_VIEW = "TOOL.EMAIL_DISTRIBUTION.VIEW",
  TOOL_EMAIL_DISTRIBUTION_EDIT = "TOOL.EMAIL_DISTRIBUTION.EDIT",
  TOOL_EMAIL_DISTRIBUTION_DELETE = "TOOL.EMAIL_DISTRIBUTION.DELETE",
  TOOL_DISTRIBUTION_LIST_EXPORT = "TOOL.DISTRIBUTION_LIST.EXPORT",

  TOOL_TEMPLATE_VIEW = "TOOL.TEMPLATE.VIEW",
  TOOL_TEMPLATE_EDIT = "TOOL.TEMPLATE.EDIT",
  TOOL_TEMPLATE_DELETE = "TOOL.TEMPLATE.DELETE",
  TOOL_TEMPLATE_CREATE = "TOOL.TEMPLATE.CREATE",

  TOOL_QA_INSPECTION_TEMPLATE_VIEW = "TOOL.QA_INSPECTION_TEMPLATE.VIEW",
  TOOL_QA_INSPECTION_TEMPLATE_EDIT = "TOOL.QA_INSPECTION_TEMPLATE.EDIT",
  TOOL_QA_INSPECTION_TEMPLATE_DELETE = "TOOL.QA_INSPECTION_TEMPLATE.DELETE",
  TOOL_QA_INSPECTION_TEMPLATE_CREATE = "TOOL.QA_INSPECTION_TEMPLATE.CREATE",

  TOOL_MAILING_LIST_VIEW = "TOOL.MAILING_LIST.VIEW",
  TOOL_MAILING_LIST_EDIT = "TOOL.MAILING_LIST.EDIT",
  TOOL_MAILING_LIST_DELETE = "TOOL.MAILING_LIST.DELETE",
  TOOL_MAILING_LIST_CREATE = "TOOL.MAILING_LIST.CREATE",
  TOOL_MAILING_LIST_EXPORT = "TOOL.MAILING_LIST.EXPORT",

  TOOL_ROLE_AND_PERMISSION_VIEW = "TOOL.ROLE_AND_PERMISSION.VIEW",
  TOOL_ROLE_AND_PERMISSION_EDIT = "TOOL.ROLE_AND_PERMISSION.EDIT",
  TOOL_ROLE_AND_PERMISSION_DELETE = "TOOL.ROLE_AND_PERMISSION.DELETE",
  TOOL_ROLE_AND_PERMISSION_CREATE = "TOOL.ROLE_AND_PERMISSION.CREATE",

  TOOL_PROJECT_REQUEST_VIEW = "TOOL.PROJECT_REQUEST.VIEW",
  TOOL_PROJECT_REQUEST_CREATE = "TOOL.PROJECT_REQUEST.CREATE",
  TOOL_PROJECT_REQUEST_EDIT = "TOOL.PROJECT_REQUEST.EDIT",
  TOOL_PROJECT_REQUEST_DELETE = "TOOL.PROJECT_REQUEST.DELETE",
  TOOL_PROJECT_REQUEST_APPROVE = "TOOL.PROJECT_REQUEST.APPROVE",
  TOOL_PROJECT_REQUEST_EXPORT = "TOOL.PROJECT_REQUEST.EXPORT",

  TOOL_PROJECT_STOP_REQUEST_VIEW = "TOOL.PROJECT_STOP_REQUEST.VIEW",
  TOOL_PROJECT_STOP_REQUEST_CREATE = "TOOL.PROJECT_STOP_REQUEST.CREATE",
  TOOL_PROJECT_STOP_REQUEST_EXPORT = "TOOL.PROJECT_STOP_REQUEST.EXPORT",
  TOOL_PROJECT_STOP_REQUEST_APPROVE = "TOOL.PROJECT_STOP_REQUEST.APPROVE",
  TOOL_PROJECT_STOP_REQUEST_EDIT = "TOOL.PROJECT_STOP_REQUEST.EDIT",
  TOOL_PROJECT_STOP_REQUEST_DELETE = "TOOL.PROJECT_STOP_REQUEST.DELETE",

  TOOL_COMPLIANCE_REQUEST_VIEW = "TOOL.COMPLIANCE_REQUEST.VIEW",
  TOOL_COMPLIANCE_REQUEST_CREATE = "TOOL.COMPLIANCE_REQUEST.CREATE",
  TOOL_COMPLIANCE_REQUEST_EXPORT = "TOOL.COMPLIANCE_REQUEST.EXPORT",
  TOOL_COMPLIANCE_REQUEST_EDIT = "TOOL.COMPLIANCE_REQUEST.EDIT",
  TOOL_COMPLIANCE_REQUEST_DELETE = "TOOL.COMPLIANCE_REQUEST.DELETE",
  TOOL_COMPLIANCE_REQUEST_APPROVE = "TOOL.COMPLIANCE_REQUEST.APPROVE",

  TOOL_PROJECT_ACTIVITY_REPORT_VIEW = "TOOL.PROJECT_ACTIVITY_REPORT.VIEW",

  TOOL_SITE_MAP_VIEW = "TOOL.SITE_MAP.VIEW",
  TOOL_SITE_MAP_CREATE = "TOOL.SITE_MAP.CREATE",
  TOOL_SITE_MAP_EDIT = "TOOL.SITE_MAP.EDIT",
  TOOL_SITE_MAP_DELETE = "TOOL.SITE_MAP.DELETE",

  TOOL_QUESTION_CATEGORY_VIEW = "TOOL.QUESTION_CATEGORY.VIEW",
  TOOL_QUESTION_CATEGORY_CREATE = "TOOL.QUESTION_CATEGORY.CREATE",
  TOOL_QUESTION_CATEGORY_EDIT = "TOOL.QUESTION_CATEGORY.EDIT",
  TOOL_QUESTION_CATEGORY_DELETE = "TOOL.QUESTION_CATEGORY.DELETE",

  TOOL_SAFETY_OBSERVATION_CATEGORY_VIEW = "TOOL.SAFETY_OBSERVATION_CATEGORY.VIEW",
  TOOL_SAFETY_OBSERVATION_CATEGORY_CREATE = "TOOL.SAFETY_OBSERVATION_CATEGORY.CREATE",
  TOOL_SAFETY_OBSERVATION_CATEGORY_EDIT = "TOOL.SAFETY_OBSERVATION_CATEGORY.EDIT",
  TOOL_SAFETY_OBSERVATION_CATEGORY_DELETE = "TOOL.SAFETY_OBSERVATION_CATEGORY.DELETE",
  TOOL_SAFETY_OBSERVATION_ISSUE_VIEW = "TOOL.SAFETY_OBSERVATION_ISSUE.VIEW",
  TOOL_SAFETY_OBSERVATION_ISSUE_CREATE = "TOOL.SAFETY_OBSERVATION_ISSUE.CREATE",
  TOOL_SAFETY_OBSERVATION_ISSUE_EDIT = "TOOL.SAFETY_OBSERVATION_ISSUE.EDIT",
  TOOL_SAFETY_OBSERVATION_ISSUE_DELETE = "TOOL.SAFETY_OBSERVATION_ISSUE.DELETE",

  TOOL_QA_OBSERVATION_CATEGORY_VIEW = "TOOL.QA_OBSERVATION_CATEGORY.VIEW",
  TOOL_QA_OBSERVATION_CATEGORY_CREATE = "TOOL.QA_OBSERVATION_CATEGORY.CREATE",
  TOOL_QA_OBSERVATION_CATEGORY_EDIT = "TOOL.QA_OBSERVATION_CATEGORY.EDIT",
  TOOL_QA_OBSERVATION_CATEGORY_DELETE = "TOOL.QA_OBSERVATION_CATEGORY.DELETE",
  TOOL_QA_OBSERVATION_ISSUE_VIEW = "TOOL.QA_OBSERVATION_ISSUE.VIEW",
  TOOL_QA_OBSERVATION_ISSUE_CREATE = "TOOL.QA_OBSERVATION_ISSUE.CREATE",
  TOOL_QA_OBSERVATION_ISSUE_EDIT = "TOOL.QA_OBSERVATION_ISSUE.EDIT",
  TOOL_QA_OBSERVATION_ISSUE_DELETE = "TOOL.QA_OBSERVATION_ISSUE.DELETE",

  TOOL_QA_OBSERVATION_TRADE_VIEW = "TOOL.QA_OBSERVATION_TRADE.VIEW",
  TOOL_QA_OBSERVATION_TRADE_CREATE = "TOOL.QA_OBSERVATION_TRADE.CREATE",
  TOOL_QA_OBSERVATION_TRADE_EDIT = "TOOL.QA_OBSERVATION_TRADE.EDIT",
  TOOL_QA_OBSERVATION_TRADE_DELETE = "TOOL.QA_OBSERVATION_TRADE.DELETE",

  TOOL_SAFETY_INSPECTION_TEMPLATE_VIEW = "TOOL.SAFETY_INSPECTION_TEMPLATE.VIEW",
  TOOL_SAFETY_INSPECTION_TEMPLATE_EDIT = "TOOL.SAFETY_INSPECTION_TEMPLATE.EDIT",
  TOOL_SAFETY_INSPECTION_TEMPLATE_CREATE = "TOOL.SAFETY_INSPECTION_TEMPLATE.CREATE",
  TOOL_SAFETY_INSPECTION_TEMPLATE_DELETE = "TOOL.SAFETY_INSPECTION_TEMPLATE.DELETE",

  TOOL_OPTION_DELETE = "TOOL.OPTION.DELETE",
  TOOL_OPTION_CREATE = "TOOL.OPTION.CREATE",
  TOOL_OPTION_EDIT = "TOOL.OPTION.EDIT",
  TOOL_VIEW_EXTERNAL_LINK = "TOOL.EXTERNAL_LINK.VIEW",

  TOOL_QA_SERVICE_TYPE_VIEW = "TOOL.QA_SERVICE_TYPE.VIEW",
  TOOL_QA_SERVICE_TYPE_CREATE = "TOOL.QA_SERVICE_TYPE.CREATE",
  TOOL_QA_SERVICE_TYPE_EDIT = "TOOL.QA_SERVICE_TYPE.EDIT",
  TOOL_QA_SERVICE_TYPE_DELETE = "TOOL.QA_SERVICE_TYPE.DELETE",

  RAIN_LOG_EDIT = "RAIN_LOG.EDIT",
  RAIN_LOG_DELETE = "RAIN_LOG.DELETE",
  RAIN_LOG_VIEW = "RAIN_LOG.VIEW",
  RAIN_LOG_EXPORT = "RAIN_LOG.EXPORT",
  RAIN_LOG_CREATE = "RAIN_LOG.CREATE"
}
