import { Injectable } from "@angular/core";
import { environment } from "@emc-environment/environment";
import * as Sentry from "@sentry/browser";
import { UserFull } from "@emc-models/entities/user.model";

@Injectable()
export class SentryService {
  captureMessage(msg: string, level: Sentry.Severity) {
    if (environment.production) {
      Sentry.captureMessage(msg, level);
    }
  }

  setUserContext(user: UserFull) {
    if (environment.production) {
      Sentry.setUser({
        id: String(user.id),
        email: user.email,
        username: user.first_name + " " + user.last_name,
        role: user?.role?.title
      });
    }
  }
}
